@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: white;
}

.text-white {
  color: #fff;
}